import { v as gsap, w as CSSPlugin } from './bundled-app.js';

var gsapWithCSS = gsap.registerPlugin(CSSPlugin) || gsap;
    // to protect from tree shaking
gsapWithCSS.core.Tween;
// export * from "./DrawSVGPlugin.js";
// export * from "./Physics2DPlugin.js";
// export * from "./PhysicsPropsPlugin.js";
// export * from "./ScrambleTextPlugin.js";
// export * from "./CustomBounce.js";
// export * from "./CustomWiggle.js";
// export * from "./GSDevTools.js";
// export * from "./InertiaPlugin.js";
// export * from "./MorphSVGPlugin.js";
// export * from "./MotionPathHelper.js";
// export * from "./ScrollSmoother.js";
// export * from "./SplitText.js";

export { gsapWithCSS as g };
